.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #f8d4c7, #ffffff);
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-box {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-width: 350px;
  width: 100%;
}

.login-box:hover {
  transform: scale(1.05);
}

h4 {
  text-align: left;
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
  font-size: 14px;
}

.input-field:focus {
  border-color: #ee6f3d;
}

.login-button,
.forgot-password-button,
.back-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #ee6f3d, #ee6f3d);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, box-shadow 0.3s;
}

.login-button:hover,
.forgot-password-button:hover,
.back-button:hover {
  background: linear-gradient(to right, #ff8c66, #ff8c66);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.otp-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.otp-input {
  width: 40px;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
}
